import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";

const MemberDetail = () => {
  const [memberData, setMemberData] = useState({
    fullName: "",
    email: "",
    skills: [],
    skill: "",
    phoneNo: "",
    city: "",
    currRole: "",
    organization: "",
    about: "",
    availableTimes: [],
  });

  const [availableTimes, setAvailableTimes] = useState([]);
  const [skills, setSkills] = useState([]);
  const [skill, setSkill] = useState("");

  const handleChangeAvailableTimes = (e) => {
    const selectedValues = e.target.value;

    if (selectedValues.includes("No calls for now")) {
      setAvailableTimes(["No calls for now"]);
    } else {
      setAvailableTimes(selectedValues);
    }

    setMemberData((prev) => ({
      ...prev,
      availableTimes: selectedValues.includes("No calls for now")
        ? ["No calls for now"]
        : selectedValues,
    }));
  };

  const handleSkillsChange = (e) => {
    const input = e.target.value;

    setSkills(input.split(","));
    setMemberData((prevState) => ({
      ...prevState,
      skills: skills,
    }));
  };

  const handleSkillChange = (e) => {
    setSkill(e.target.value);
    setMemberData((prevState) => ({
      ...prevState,
      skill: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${process.env.REACT_APP_URL}/api/members/member-details`,
        memberData
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box
      padding="2rem"
      sx={{ display: { sm: "inline-block", md: "flex" } }}
      alignItems="center"
      justifyContent="space-evenly"
    >
      <Box marginBottom="20%" sx={{ width: { md: "50%", lg: "30%" } }}>
        <Box
          margin="0.1rem 39%"
          component="img"
          alt="logo"
          src="../images/company-logo.png"
          sx={{ objectFit: "cover", height: "130px", width: "130px" }}
        />
        <Typography padding="0.6rem 0" variant="h4">
          Start your journey here.
        </Typography>
        <Typography>
          Tell us about yourself so we can match you with others with similar
          skills so it helps you in building your network.
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <Box>
            <Typography>Full name </Typography>
            <TextField
              onChange={(e) =>
                setMemberData((prevState) => ({
                  ...prevState,
                  fullName: e.target.value,
                }))
              }
              value={memberData.fullName}
              fullWidth
              sx={{ margin: "7px 0" }}
              label="Full name"
              variant="outlined"
              type="text"
              required
            />
          </Box>

          <Box padding="20px 0">
            <Typography>Email </Typography>
            <TextField
              onChange={(e) =>
                setMemberData((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }))
              }
              value={memberData.email}
              fullWidth
              sx={{ margin: "7px 0" }}
              label="email"
              variant="outlined"
              type="email"
              required
            />
          </Box>

          <Box>
            <Typography>Phone number (Won't be shared with others) </Typography>
            <TextField
              onChange={(e) =>
                setMemberData((prevState) => ({
                  ...prevState,
                  phoneNo: e.target.value,
                }))
              }
              value={memberData.phoneNo}
              fullWidth
              sx={{ margin: "7px 0" }}
              label="Phone no"
              variant="outlined"
              type="text"
              required
            />
          </Box>

          <Box padding="20px 0">
            <Typography>City </Typography>
            <TextField
              onChange={(e) =>
                setMemberData((prevState) => ({
                  ...prevState,
                  city: e.target.value,
                }))
              }
              value={memberData.city}
              fullWidth
              sx={{ margin: "7px 0" }}
              label="your city"
              variant="outlined"
              type="text"
              required
            />
          </Box>

          <Box>
            <Typography>Current role </Typography>
            <TextField
              onChange={(e) =>
                setMemberData((prevState) => ({
                  ...prevState,
                  currRole: e.target.value,
                }))
              }
              value={memberData.currRole}
              fullWidth
              sx={{ margin: "7px 0" }}
              label="your current role"
              variant="outlined"
              type="text"
              required
            />
          </Box>

          <Box padding="20px 0">
            <Typography>
              Current or last organization you worked for *
            </Typography>
            <TextField
              onChange={(e) =>
                setMemberData((prevState) => ({
                  ...prevState,
                  organization: e.target.value,
                }))
              }
              value={memberData.organization}
              fullWidth
              sx={{ margin: "7px 0" }}
              label="organization"
              variant="outlined"
              type="text"
              required
            />
          </Box>

          <Box marginBottom="20px">
            <Typography>Skill</Typography>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Skill</InputLabel>
              <Select
                onChange={handleSkillChange}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Age"
                required
                value={skill}
              >
                <MenuItem value={"BA"}>Buisness Analyst</MenuItem>
                <MenuItem value={"FD"}>Fullstack Developer</MenuItem>
                <MenuItem value={"Marketing"}>Marketing</MenuItem>
                <MenuItem value={"DS"}>Data Scientist</MenuItem>
                <MenuItem value={"DE"}>Devops engineer</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box>
            <Typography>
              Skills (enter your additional skills here, separated by comma)
            </Typography>
            <TextField
              onChange={handleSkillsChange}
              fullWidth
              sx={{ margin: "7px 0" }}
              label="skills"
              variant="outlined"
              type="text"
              value={skills}
            />
          </Box>

          <Box padding="20px 0">
            <Typography>About me</Typography>
            <TextField
              onChange={(e) =>
                setMemberData((prevState) => ({
                  ...prevState,
                  about: e.target.value,
                }))
              }
              value={memberData.about}
              fullWidth
              multiline
              rows={5}
              sx={{ margin: "7px 0" }}
              label="about me"
              variant="outlined"
              type="text"
              required
            />
          </Box>

          <Box>
            <Typography>
              Let us know what times you can have a call with other members
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select available time slot
              </InputLabel>
              <Select
                multiple
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={availableTimes}
                label="Age"
                onChange={handleChangeAvailableTimes}
              >
                <MenuItem value={"11.30 AEST"}>11.30 AEST</MenuItem>
                <MenuItem value={"12.30 AEST"}>12.30 AEST</MenuItem>
                <MenuItem value={"1.30 AEST"}>1.30 AEST</MenuItem>
                <MenuItem value={"No calls for now"}>No calls for now</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box padding="20px 0">
            <Typography>Profile Picture</Typography>
            <TextField
              fullWidth
              sx={{ margin: "7px 0" }}
              variant="outlined"
              type="file"
            />
          </Box>
          <Button
            type="submit"
            sx={{ color: "#fff", backgroundColor: "#1cdb35" }}
          >
            SUBMIT
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default MemberDetail;
