import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const RegisterForm = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [enterAllFields, setAllFields] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const nav = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newUser = await axios.post(
        `${process.env.REACT_APP_URL}/api/auth/register`,
        {
          name: username,
          email: email,
          password: password,
        }
      );
      if (username === "" || email === "" || password === "") {
        setAllFields(true);
      }
      setUsername("");
      setEmail("");
      setPassword("");
      setCheckbox(!checkbox);
      console.log(newUser.data);
      nav("/member-details");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: "#fff",
        width: { xs: "90%", sm: "60%", md: "43%", xl: "24%" },
        margin: { xs: "0 15px" },
      }}
      padding="20px"
      borderRadius="5%"
    >
      <Box textAlign="center">
        <Box
          component="img"
          alt="logo"
          src="../images/company-logo.png"
          sx={{ objectFit: "cover", height: "130px", width: "130px" }}
        />

        <Typography variant="h5" padding="10px" textAlign="center">
          SIGN UP
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box
          display="flex"
          flexDirection="column"
          sx={{ width: { xs: "18rem", sm: "25rem" } }}
        >
          <TextField
            onChange={(e) => setUsername(e.target.value)}
            value={username}
            sx={{ margin: "7px 0" }}
            label="Full Name"
            variant="outlined"
            type="text"
            required
          />
          <TextField
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            sx={{ margin: "7px 0" }}
            label="Email"
            variant="outlined"
            type="email"
            required
          />
          <TextField
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            minLength={8}
            sx={{ margin: "7px 0" }}
            label="Password"
            variant="outlined"
            type="password"
            required
          />
          {/* <Typography color="red" fontSize="13px">
            ! Password's length should be atleaset 8 characters.
          </Typography> */}
          <Box margin="20px 0" display="flex">
            <input onClick={() => setCheckbox(!checkbox)} type="checkbox" />
            <Typography sx={{ ml: 1, fontSize: "14px" }}>
              I agree to the Terms and Privacy Policy
            </Typography>
          </Box>
          {enterAllFields && (
            <Typography color="red" fontSize="14px">
              All fields are required
            </Typography>
          )}
          <Button
            disabled={!checkbox ? true : false}
            type="submit"
            sx={{ margin: "6px 0", backgroundColor: "#1cdb35" }}
            variant="contained"
          >
            Sign Up
          </Button>
          <Button sx={{ margin: "6px 0" }} variant="outlined">
            Sign In
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default RegisterForm;
