import React from "react";
import RegisterForm from "../components/registerForm";
import { Box } from "@mui/material";

const Register = () => {
  return (
    <Box
      sx={{ backgroundColor: "#51ade5" }}
      display="flex"
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <RegisterForm />
    </Box>
  );
};

export default Register;
